// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-text-page-jsx": () => import("/opt/build/repo/src/templates/TextPage.jsx" /* webpackChunkName: "component---src-templates-text-page-jsx" */),
  "component---src-pages-about-index-jsx": () => import("/opt/build/repo/src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("/opt/build/repo/src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-jsx": () => import("/opt/build/repo/src/pages/contact/thank-you.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

